import { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, Typography, CircularProgress } from "@mui/material";
import { clientService, IDetalheCliente, IListagemCliente } from "../../../shared/services/api/client/clientService";
import { IVFormErros, useVForm, VForm } from "../../../shared/forms";
import { FerramentaDetalhe, ModalContext } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { useToast } from "../../../shared/hooks";
import { globalRemoveMaskReal, maskPhone } from "../../../shared/utils";
import { useProfile } from "../../../context/ProfileContext";
import { ToastContent } from "../../../shared/components";
import { useDebounce } from "../../../shared/hooks";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Logs } from "../../../shared/interfaces";
import { isObject } from "lodash";
import { useAuthContext } from "../../../context";
import moment from "moment";
import { ClientFollowUpModalContent } from "./ClientFollowUpModalContent";
import { GenerateClient } from "./createComponents/GenerateClient";
import { BankClient } from "./createComponents/BankClient";
import { AddressClient } from "./createComponents/AddressClient";
import { CommercialGridClient } from "./RelationComponents/Commercial";
import { ManagerClient } from "./RelationComponents/Manager";
import { ParamsClient } from "./createComponents/Parametrizes";
import { ContactsClient } from "./RelationComponents/ContactsClient";
import Contracts from "./contractsComponents/Contracts";

interface IFormData {
	document: string;
	status: string;
	email: string;
	name: string;
	trade_name: string;
	responsible_name: string;
	responsible_document: string;
	phone: string;
	address_zipcode: string;
	address_street: string;
	address_number: string;
	address_district: string;
	address_city: string;
	address_state: string;
	address_ibge?: string;
	bank_manager?: string;
	bank_code?: string;
	agency_number?: string;
	agency_digit?: string;
	account_number?: string;
	invoice_due_date: number;
	invoiceGateway?: string;
	mobilePhone?: string;
	observationPartner?: string;
	origem?: string;
	typeId: string;
	baseRateCalc?: string;
	activeSignature?: boolean;
	signContract?: string;
	tax_setup_enable?: boolean;
	tax_setup_limit_default?: string;
	tax_setup_limit_min?: string;
	tax_setup_limit_max?: string;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
	document: yup.string().min(11).required("Campo Obrigatório"),
	typeId: yup.string().required("Campo Obrigatório"),
	status: yup.string().required("Campo Obrigatório"),
	email: yup.string().min(3).required("Campo Obrigatório"),
	creci: yup.string().nullable(),
	name: yup.string().min(3).required("Campo Obrigatório"),
	trade_name: yup.string().required("Campo Obrigatório"),
	responsible_name: yup.string().required("Campo Obrigatório"),
	responsible_document: yup.string().required("Campo Obrigatório"),

	phone: yup.string().min(10).required("Campo Obrigatório"),
	address_zipcode: yup.string().required("Campo Obrigatório"),
	address_street: yup.string().required("Campo Obrigatório"),
	address_number: yup.string().required("Campo Obrigatório"),
	address_complement: yup.string().nullable(),
	address_district: yup.string().required("Campo Obrigatório"),
	address_city: yup.string().required("Campo Obrigatório"),
	address_state: yup.string().required("Campo Obrigatório"),
	address_ibge: yup.string().notRequired(),
	bank_manager: yup.string().required("Campo Obrigatório").nullable(),
	bank_code: yup.string().required("Campo Obrigatório").nullable(),
	agency_number: yup.string().required("Campo Obrigatório").nullable(),
	agency_digit: yup.string().required("Campo Obrigatório").nullable(),
	account_number: yup.string().required("Campo Obrigatório").nullable(),

	invoice_due_date: yup.number().required("Campo Obrigatório"),
	invoiceGateway: yup.string().required("Campo Obrigatório"),
	mobilePhone: yup.string().notRequired(),
	observationPartner: yup.string().optional(),
	origem: yup.string().optional(),
	baseRateCalc: yup.string().notRequired(),
	activeSignature: yup.boolean().notRequired(),
	signContract: yup.string().notRequired(),
	tax_setup_enable: yup.boolean().notRequired(),
	tax_setup_limit_default: yup.string().notRequired(),
	tax_setup_limit_min: yup.string().notRequired(),
	tax_setup_limit_max: yup.string().notRequired(),
});

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export const DetalheClientesPresentation: React.FC = () => {
	const { id = "novo" } = useParams<"id">();
	const navigate = useNavigate();
	const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [nome, setNome] = useState("");
	const [value, setValue] = useState(0);
	const [row, setRow] = useState<IDetalheCliente>();
	const [clientType, setClientType] = useState("legal");
	const toastHandler = useToast();
	const { debounce } = useDebounce();
	const { isSuperAdmin, permissionLevel } = useProfile();
	const [loading, setLoading] = useState(false);
	const [partners, setPartners] = useState<IListagemCliente[]>([]);
	const { loggedUser } = useAuthContext();
	const [openModalLogs, setOpenModalLogs] = useState(false);
	const [openModalLogsPayload, setOpenModalLogsPayload] = useState(false);
	const [isLoadingLogs, setIsLoadingLogs] = useState(false);
	const [dataLogs, setDataLogs] = useState<Logs[]>([]);
	const [payload, setPayload] = useState<any>(undefined);
	const [idLog, setIdLog] = useState("");
	const [openModalFollowUp, setOpenModalFollowUp] = useState(false);
	const [activeSignature, setActiveSignature] = useState(false);
	const [signContract, setSignContract] = useState(true);
	const [activeSetup, setActiveSetup] = useState(false);
	const [baseRateCalc, setBaseRateCalc] = useState<any>("aluguel");

	const handleOpenModalLogs = () => {
		setOpenModalLogs(true);
	};

	const handleCloseModalLogs = () => {
		setOpenModalLogs(false);
	};

	const handleOpenModalLogsPayload = () => {
		setOpenModalLogsPayload(true);
	};

	const handleCloseModalLogsPayload = () => {
		setOpenModalLogsPayload(false);
	};

	const handleGetLogs = (clientId: string) => {
		setIsLoadingLogs(true);
		handleOpenModalLogs();
		clientService
			.getLogs(clientId)
			.then((result) => {
				setIsLoadingLogs(false);

				if (result instanceof Error) {
					setOpenModalLogs(false);
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					setDataLogs(result);
				}
			})
			.catch((err: any) => {
				setIsLoadingLogs(false);
				setOpenModalLogs(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	};

	const handleGetLogsPayload = (payload?: Object, logId: string = "") => {
		if (isObject(payload)) {
			setPayload(payload);
			setIdLog(logId);
			handleOpenModalLogsPayload();
		} else {
			handleCloseModalLogsPayload();
			toastHandler.present({
				type: "warning",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta!!"} content={"Não foi encontrado evidência de payload neste log especifico!"} />,
			});
		}
	};

	const handleOpenModalFollowUp = () => {
		setOpenModalFollowUp(true);
	};

	const getPartnersByClient = () => {
		if (id !== "novo") {
			setLoading(true);
			debounce(() => {
				clientService
					.getPartners(id)
					.then((result) => {
						setLoading(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else setPartners(result.data);
					})
					.catch((err: any) => {
						setLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	};

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleClientType = (e: any) => {
		setClientType(e.target.value);
		formRef.current?.setFieldValue("document", " ");
	};

	const checkCEP = (e: any) => {
		const cep = e.target.value.replace(/\D/g, "");
		if (cep.length !== 8) {
			formRef.current?.setFieldValue("address_zipcode", cep);
			formRef.current?.setFieldValue("address_street", "");
			formRef.current?.setFieldValue("residence_typeo", "");
			formRef.current?.setFieldValue("address_number", "");
			formRef.current?.setFieldValue("address_complement", "");
			formRef.current?.setFieldValue("address_district", "");
			formRef.current?.setFieldValue("address_city", "");
			formRef.current?.setFieldValue("address_state", "");
			formRef.current?.setFieldValue("address_ibge", "3113404");
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: (
					<ToastContent title={"Alerta!!"} content={"CEP não encontrado! Será necessario preecher o endereco de forma manual"} />
				),
			});
		}
		else {
			fetch(`https://viacep.com.br/ws/${cep}/json/`)
				.then((res) => res.json())
				.then((data) => {
					if (data.hasOwnProperty("erro")) {
						formRef.current?.setFieldValue("address_zipcode", cep);
						formRef.current?.setFieldValue("address_street", "");
						formRef.current?.setFieldValue("residence_typeo", "");
						formRef.current?.setFieldValue("address_number", "");
						formRef.current?.setFieldValue("address_complement", "");
						formRef.current?.setFieldValue("address_district", "");
						formRef.current?.setFieldValue("address_city", "");
						formRef.current?.setFieldValue("address_state", "");
						formRef.current?.setFieldValue("address_ibge", "3113404");
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: (
								<ToastContent title={"Alerta!!"} content={"CEP não encontrado! Será necessario preecher o endereco de forma manual"} />
							),
						});
					} else {
						formRef.current?.setFieldValue("address_zipcode", data.cep);
						formRef.current?.setFieldValue("address_street", data.logradouro);
						formRef.current?.setFieldValue("residence_typeo", "");
						formRef.current?.setFieldValue("address_district", data.bairro);
						formRef.current?.setFieldValue("address_city", data.localidade);
						formRef.current?.setFieldValue("address_state", data.uf);
						formRef.current?.setFieldValue("address_ibge", data.ibge);
					}
				});
		}
	};

	useEffect(() => {
		if (id !== "novo") {
			setIsLoading(true);
			clientService.getByID(id).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
					navigate("/clientes");
				} else {
					setNome(result.name);
					formRef.current?.setData({
						...result,
						phone: result.phone ? maskPhone(result.phone) : undefined,
						mobilePhone: result.mobilePhone ? maskPhone(result.mobilePhone) : undefined,
						origem: result.created_from == "web_app" ? "Web APP" : "Web Service",
					});

					setActiveSignature(result.activeSignature == 1 ? true : false);
					setSignContract(result.signContract == "sign" ? true : false);
					setActiveSetup(result.tax_setup_enable == 1 ? true : false);
					//setBaseRateCalc(result.baseRateCalc);
					setRow(result);
					//const commercial = result.__client_plan__ as unknown as ICommercialGrid[];
					//setPlanos(commercial)
				}
			});
		} else {
			formRef.current?.setData({
				name: "",
				responsible_name: "",
				responsible_document: "",
				type_person: "legal",
				type: "",
				document: " ",
				trade_name: "",
				creci: "",
				phone: " ",
				mobilePhone: "",
				email: "",
				address_zipcode: " ",
				address_street: "",
				address_number: "",
				typecomplement: "",
				address_complement: "",
				address_district: "",
				address_city: "",
				address_state: "",
				status: "active",
				created_from: "web",
				invoice_due_date: "10",
				invoiceGateway: "SICOOB",
				cadastro: new Date(),
				pix_key: "",
				agency_number: "",
				agency_digit: "",
				account_number: "",
				account_digit: "",
				bank_manager: "",
				observationPartner: "",
			});
		}
	}, [id]);

	const handleSave = (dados: IFormData) => {
		setIsLoading(true);
		dados.document = dados.document.replace(/[^a-zA-Z0-9\s]/g, "");
		dados.phone = dados.phone.replace(/[^0-9]/g, "");
		dados.mobilePhone = dados.mobilePhone ? dados.mobilePhone.replace(/[^0-9]/g, "") : "";
		dados.observationPartner = !dados.observationPartner ? "" : dados.observationPartner;
		delete dados.origem;
		dados.baseRateCalc = 'aluguel';//baseRateCalc;
		dados.activeSignature = activeSignature;
		dados.signContract = signContract == false ? "not_sign" : "sign";
		dados.tax_setup_enable = activeSetup;
		dados.tax_setup_limit_default = dados.tax_setup_limit_default ? globalRemoveMaskReal(dados.tax_setup_limit_default) : "0";
		dados.tax_setup_limit_max = dados.tax_setup_limit_max ? globalRemoveMaskReal(dados.tax_setup_limit_max) : "0";
		dados.tax_setup_limit_min = dados.tax_setup_limit_min ? globalRemoveMaskReal(dados.tax_setup_limit_min) : "0";

		console.log(dados);
		formValidationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				setIsLoading(true);

				if (id === "novo") {
					clientService
						.create(dadosvalidados)
						.then((result) => {
							setIsLoading(false);
							if (issaveAndClose()) {
								navigate(`/clientes/`);
							} else {
								navigate(`/clientes/detalhe/${result}`);
							}
						})
						.catch((error) => {
							console.log(error)
							setIsLoading(false);
							toastHandler.present({
								type: "error",
								position: "top-center",
								messageOrContent: error.message,
							});
						});
				} else {
					clientService
						.updateById(id, { id, ...dadosvalidados })
						.then((result) => {
							setIsLoading(false);
							console.log(dadosvalidados);
							if (result instanceof Error) {
								const titleMessage = "Alerta!!";
								const contentMessage = "Erro: " + result?.message;

								toastHandler.present({
									type: "error",
									position: "top-right",
									messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
								});
							} else {
								if (issaveAndClose()) {
									navigate(`/clientes/`);
								}
								toastHandler.present({
									type: "success",
									position: "top-right",
									messageOrContent: <ToastContent title={"Sucesso"} content={"Cliente salvo com sucesso !"} />,
								});
							}
						})
						.catch((error) => {
							setIsLoading(false);

							const { titleMessage, contentMessage } = returnMessageError(error);
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						});
				}
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});

				formRef.current?.setErrors(validationErros);
				setIsLoading(false);
			});
	};

	return (
		<LayoutBaseDePagina
			titulo="Clientes"
			subtitulo={id === "novo" ? "Cadastro de novo cliente" : `Alteração de cliente [ ${nome} ]`}
			barraDeFerramentas={
				<FerramentaDetalhe
					textoButtonNew="novo"
					showButtonNew={id !== "novo" && checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Add)}
					showBotaoSalvar={!isLoading && checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Update) ? true : false}
					showBotaoSalvarFechar={!isLoading && checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Update) ? true : false}
					showBotaoApagar={false}
					showButtonFollowUp={!isLoading && isSuperAdmin}
					clicarSalvar={save}
					clicarSalvarFechar={saveAndClose}
					clicarNovo={() => navigate("/clientes/detalhe/novo")}
					clicarVoltar={() => navigate("/clientes")}
					clickButtonFollowUp={handleOpenModalFollowUp}
				/>
			}
		>
			{openModalFollowUp && (
				<ModalContext
					isCloseModal={() => setOpenModalFollowUp(false)}
					isOpenModal={openModalFollowUp}
					messageType={"question"}
					showButtonCancel={false}
					showButtonConfirm={false}
					showTitleIcon={false}
					textButtonCancel="Fechar"
					enableBackdropClose={true}
					overrideWidthValue={"65%"}
					overrideHeightValue={"100%"}
					ITitle={"FollowUp Imobiliária"}
					IMessage={[<ClientFollowUpModalContent clientId={id} />]}
				/>
			)}

			<Box sx={{ width: "100%", maxWidth: "1300px" }}>
				<VForm
					id="formIdSave"
					placeholder="Your placeholder"
					onPointerEnterCapture={() => console.log("Pointer entered")}
					onPointerLeaveCapture={() => console.log("Pointer left")}
					ref={formRef}
					onSubmit={handleSave}
				>
					<GenerateClient ClientType={clientType} handleClientType={handleClientType} isLoading={isLoading} setNome={setNome} />
					<BankClient isLoading={isLoading} />

					<AddressClient checkCEP={checkCEP} isLoading={isLoading} />

					<ParamsClient
						baseRateCalc={baseRateCalc}
						setBaseRateCalc={setBaseRateCalc}
						isLoading={isLoading}
						isSuperAdmin={isSuperAdmin}
						activeSignature={activeSignature}
						setActiveSignature={setActiveSignature}
						signContract={signContract}
						setSignContract={setSignContract}
						permissionLevel={permissionLevel}
						activeSetup={activeSetup}
						setActiveSetup={setActiveSetup}
					/>
					<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
						<Grid container direction="column" padding={2} spacing={2}>
							<Grid container item direction="row" spacing={2}>
								<Grid item md={11}>
									<Typography variant="h6" color={"primary"}>
										Relacionamento e comercial
									</Typography>
								</Grid>
							</Grid>

							<Box sx={{ width: "100%" }}>
								<Box
									sx={{
										borderBottom: 1,
										borderColor: "divider",
									}}>

									<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
										{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && <Tab label="Grade Comercial" {...a11yProps(0)} />}

										{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && <Tab label="Gestores de Contas" {...a11yProps(1)} />}

										{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && (
											<Tab label="Parceiros" {...a11yProps(2)} onClick={getPartnersByClient} />
										)}

										{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && <Tab label="Contatos / Emails" {...a11yProps(1)} />}
										{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && <Tab label="Contratos" {...a11yProps(3)} />}
									</Tabs>
								</Box>
								{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && (
									<TabPanel value={value} index={0}>
										<CommercialGridClient id={id} isLoading={isLoading} />
									</TabPanel>
								)}

								{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && (
									<TabPanel value={value} index={1}>
										<ManagerClient id={id} isLoading={isLoading} isSuperAdmin={isSuperAdmin} permissionLevel={permissionLevel} />
									</TabPanel>
								)}

								{id !== "novo" && (
									<TabPanel value={value} index={2}>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<TableContainer
													component={Paper}
													variant="outlined"
													sx={{
														marginLeft: 1,
														marginTop: 1,
														marginRight: 2,
														width: "auto",
														padding: 4,
														overflow: "auto",
													}}
													aria-label="customized table"
												>
													<Table aria-label="a dense table">
														<TableRow>
															<TableCell
																sx={{
																	color: "#7e7777b5",
																}}
															>
																{" "}
																Nome
															</TableCell>
															<TableCell
																sx={{
																	color: "#7e7777b5",
																}}
															>
																{" "}
																E-mail
															</TableCell>
															<TableCell
																sx={{
																	color: "#7e7777b5",
																}}
															>
																{" "}
																Status
															</TableCell>
														</TableRow>
														<TableBody>
															{loading ? (
																<TableRow>
																	{" "}
																	<TableCell
																		sx={{
																			textAlign: "center",
																		}}
																		colSpan={4}
																	>
																		<CircularProgress />{" "}
																	</TableCell>{" "}
																</TableRow>
															) : !partners?.length ? (
																<TableRow>
																	{" "}
																	<TableCell
																		sx={{
																			textAlign: "center",
																		}}
																		colSpan={4}
																	>
																		{" "}
																		Nenhum parceiro encontrado!{" "}
																	</TableCell>{" "}
																</TableRow>
															) : (
																partners?.map((partner, index) => (
																	<TableRow key={index} hover>
																		<TableCell
																			sx={{
																				fontWeight: "normal",
																			}}
																		>
																			<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
																				{partner.name}
																			</Typography>
																		</TableCell>
																		<TableCell
																			sx={{
																				fontWeight: "normal",
																			}}
																		>
																			<Typography variant="subtitle2" fontWeight={"normal"}>
																				{partner.email}
																			</Typography>
																		</TableCell>
																		<TableCell
																			sx={{
																				fontWeight: "normal",
																			}}
																		>
																			<Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"}>
																				{partner.status == "active" ? "Ativo" : "Inativo"}
																			</Typography>
																		</TableCell>
																	</TableRow>
																))
															)}
														</TableBody>
													</Table>
												</TableContainer>
											</Grid>
										</Grid>
									</TabPanel>
								)}

								{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && (
									<TabPanel value={value} index={3}>
										<ContactsClient id={id} isLoading={isLoading} />
									</TabPanel>
								)}

								{(isSuperAdmin || permissionLevel == 2) && id !== "novo" && (
									<TabPanel value={value} index={4}>
										<Contracts clientId={id} />
									</TabPanel>
								)}
							</Box>
						</Grid>

						<Grid container item direction="row" paddingLeft={4} spacing={2} mb={2}>
							<Grid item xs={12}>
								<Typography variant="h6" fontWeight={"bold"} color={"text.secondary"}>
									Cadastrado em: {moment(row?.createdAt).format("DD/MM/YYYY - HH:mm")}
								</Typography>
							</Grid>
							<Grid item xs={12} style={{ paddingTop: "1px" }}>
								<Typography variant="h6" fontWeight={"bold"} color={"text.secondary"}>
									Última alteração: {moment(row?.updated_at).format("DD/MM/YYYY - HH:mm")}
								</Typography>
							</Grid>
						</Grid>
					</Box>

					{
						/* *********** Logs do Cliente */
						loggedUser?.role == "admin" && (
							<Grid container display={"flex"} flexDirection={"row"} sx={{ width: "98%", marginBottom: "5px" }}>
								<Grid container display={"flex"} flexDirection={"row"} component={Paper} padding={2}>
									<Grid container sm={12} direction="row" spacing={1}>
										<Grid item xs={11}>
											<Typography variant="h6" sx={{ ml: 1 }} color={"secondary"}>
												Logs do Cliente
											</Typography>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1}>
										<Grid item xs={12}>
											<Button
												color="info"
												disableElevation
												variant={"outlined"}
												startIcon={<RemoveRedEyeOutlinedIcon />}
												onClick={() => handleGetLogs(id)}
											>
												Visualizar Logs
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					}
				</VForm>
			</Box>
			{
				// Modal Visualização dos logs
			}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogs}
					isOpenModal={openModalLogs}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Logs
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							>
								{isLoadingLogs ? (
									<CircularProgress />
								) : (
									<Grid item>
										<TableContainer
											component={Paper}
											variant="outlined"
											style={{
												marginTop: 10,
												marginRight: 1,
												marginBottom: 1,
												width: "100%",
												padding: 1,
											}}
											aria-label="customized table"
										>
											<Table aria-label="a dense table" className="" sx={{ overflow: "auto" }}>
												<TableRow>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
															width: "15%",
														}}
													>
														{" "}
														Data Criada
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
														}}
													>
														{" "}
														Tipo Evento
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
														}}
													>
														{" "}
														Nome Tabela
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
															width: "16%",
														}}
													>
														{" "}
														Referência ID Cliente
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
															width: "15%",
														}}
													>
														{" "}
														Usuário Id{" "}
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
														}}
													>
														{" "}
														Usuário Nome{" "}
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
														}}
													>
														{" "}
														Payload{" "}
													</TableCell>
												</TableRow>
												<TableBody sx={{ textAlign: "center" }}>
													{!dataLogs?.length && (
														<TableRow key={0}>
															<TableCell
																colSpan={7}
																sx={{
																	fontWeight: "normal",
																	borderBottom: "none",
																	textAlign: "center",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"} fontSize={20}>
																	Não há registros de logs desta análise
																</Typography>
															</TableCell>
														</TableRow>
													)}
													{dataLogs?.map((log: Logs, index: number) => (
														<TableRow key={index}>
															<TableCell
																sx={{
																	fontWeight: "normal",
																}}
															>
																<Typography variant="subtitle2" color={"#080808"} fontWeight={"normal"}>
																	{moment(log?.createdDate).format("DD/MM/YYYY HH:mm")}
																</Typography>
															</TableCell>
															<TableCell
																sx={{
																	fontWeight: "normal",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.eventType}
																</Typography>
															</TableCell>
															<TableCell
																sx={{
																	fontWeight: "normal",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.tableName}
																</Typography>
															</TableCell>
															<TableCell
																sx={{
																	fontWeight: "normal",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.recordId}
																</Typography>
															</TableCell>
															<TableCell
																sx={{
																	fontWeight: "normal",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.userId}
																</Typography>
															</TableCell>
															<TableCell
																sx={{
																	fontWeight: "normal",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.userName}
																</Typography>
															</TableCell>
															<TableCell>
																<Button
																	color="info"
																	disableElevation
																	variant={"outlined"}
																	sx={{
																		alignItems: "flex-start",
																	}}
																	startIcon={<RemoveRedEyeOutlinedIcon />}
																	onClick={() => handleGetLogsPayload(log?.payload, log?._id)}
																>
																	Payload
																</Button>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								)}
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{
										backgroundColor: "red",
										color: "white",
									}}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogs}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{
				// Modal Visualização do Payload Específico
			}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogsPayload}
					isOpenModal={openModalLogsPayload}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Detalhe do Payload IdLog#{idLog}
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "inline-flex",
								}}
							>
								<div>
									<pre>{JSON.stringify(payload, null, 1)}</pre>
								</div>
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{
										backgroundColor: "red",
										color: "white",
									}}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogsPayload}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
